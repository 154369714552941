<template>
  <div class="p-grid">
    <div class="p-col-12">
      <h1>{{ $t("empty_section.title") }}</h1>
      <p>{{ $t("empty_section.text") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptySection",
};
</script>
